import React from "react"
import SEO from "../components/seo"

import ContactUs from "../components/contact_us"

const Page = location => (
  <>
    <SEO
      title="Contact Us | Master Trainer"
      description="contact@mastrainer.com local +923355093999, UK +441173180889 House 26, Street 16,Sector G DHA @ Islamabad, Pakistan"
      url="/contact-us"
      //image="https://blogs.mycareerdreams.com/blogs/tips_header.webp"
      type="article"
    />

    <ContactUs location={location} />
  </>
)
export default Page
